import { createSlice } from "@reduxjs/toolkit";

// const initialState = [
//   {
//     type: "introduction",
//     title: "Discover the World of Pokemon",
//     subtitle: "Gotta catch 'em all!",
//     text: "Gotta catch 'em all!",
//     id: 0,
//     titleActive: true,
//     textActive: true,
//   },
//   {
//     type: "content",
//     title: "Pikachu",
//     text: "The iconic Electric-type Pokemon known for its cute appearance and powerful Thunderbolt attack.",
//     id: 1,
//     titleActive: true,
//     textActive: true,
//   },
//   {
//     type: "content",
//     title: "Charizard",
//     text: "A fierce Fire/Flying-type Pokemon with the ability to breathe intense flames and fly at high speeds.",
//     id: 2,
//     titleActive: true,
//     textActive: true,
//   },
//   {
//     type: "content",
//     title: "Bulbasaur, Squirtle, and Charmander",
//     text: "The original starter Pokemon representing Grass, Water, and Fire types respectively in the Kanto region.",
//     id: 3,
//     titleActive: true,
//     textActive: true,
//   },
//   {
//     type: "tldr",
//     title: "In a nutshell",
//     text: "Pokemon world is filled with diverse creatures like Pikachu, Charizard, and iconic starter Pokemon.",
//     id: 4,
//     titleActive: true,
//     textActive: true,
//   },
//   {
//     type: "outro",
//     title: "Share Your Favorite Pokemon!",
//     text: "Which Pokemon is your ultimate favorite? Comment below and let's discuss!",
//     id: 5,
//     titleActive: true,
//     textActive: true,
//   },
// ];

const initialState = [
  {
    title: "GROW YOUR LINKEDIN",
    titleActive: true,
    subtitleActive: true,
    text: "Cover slide subtitle",
    textActive: true,
    showIntro: true,
  },
  {
    title: "Slide 1",
    titleActive: true,
    subtitleActive: false,
    text: "Slide 1 text",
    textActive: false,
  },
  {
    title: "Slide 2",
    titleActive: true,
    subtitleActive: false,
    text: "Slide 2 text",
    textActive: true,
  },
  {
    title: "tldr Slide",
    titleActive: true,
    subtitleActive: false,
    text: "tldr slide  text",
    textActive: true,
    showTldr: true,
  },
  {
    title: "Outro Slide",
    titleActive: true,
    subtitleActive: false,
    text: "Outro slide text",
    textActive: true,
    showOutro: false,
  },
];

export const carouselsSlice = createSlice({
  name: "carousels",
  initialState,
  reducers: {
    setCarousels: (state, action) => action.payload,
    addCarousel: (state, action) => {
      // state.push(action.payload);

      let index = state.length - 1;
      state.splice(index, 0, action.payload);
    },
    updateCarousel: (state, action) => {
      const index = action.payload.index;

      state[index] = {
        ...state[index],
        ...action.payload.data,
      };
    },
    removeCarousel: (state, action) => {
      // eslint-disable-next-line no-unused-expressions
      [...state.splice(action.payload, 1)];
    },
    setShowIntro: (state, action) => {
      state[0].showIntro = action.payload;
    },
    setShowTldr: (state, action) => {
      state[state.length - 2].showTldr = action.payload;
    },
    setShowOutro: (state, action) => {
      state[state.length - 1].showOutro = action.payload;
    },
  },
});

export const {
  setCarousels,
  addCarousel,
  updateCarousel,
  removeCarousel,
  setShowIntro,
  setShowTldr,
  setShowOutro,
} = carouselsSlice.actions;
export default carouselsSlice.reducer;
